<template>
  <div v-click-outside="closeDropdown">
    <div>
      <MainButton
          :name="$t('board.addList')"
          :bgStyle="'light'"
          @clickAction="isOpen = !isOpen"/>
    </div>
    <div v-if="isOpen" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
      <div class="py-1" role="none">
        <div class="block px-4 py-2">
          <FormInput
              :value="newListing"
              :autofocus="true"
              :placeholder="$t('board.addListPlaceholder')"
              @handleInputChange="handleInputChange"
              @handleKeyUp="handleKeyUp"
          />
        </div>
        <div class="block px-4 py-2 flex justify-end">
          <MainButton
              :name="$t('global.buttons.add')"
              @clickAction="handleAddNewListing"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'CreateNewListingDropDown',
  components: {
    MainButton: () => import(/* webpackChunkName: "BoardMainButton" */'@/components/Main/Buttons/MainButton/MainButton'),
    FormInput: () => import(/* webpackChunkName: "BoardFormInput" */'@/components/Main/Form/FormInput/FormInput')
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      isOpen: false,
      newListing: ''
    }
  },
  methods: {
    ...mapActions({ handleCreateNewListing: 'fetchCreateBoardListing' }),
    closeDropdown() {
      this.isOpen = false;
    },
    handleAddNewListing() {
      const { id } = this.$route.params;
      this.handleCreateNewListing({ id, form: { name: this.newListing } });
      this.closeDropdown();
      this.newListing = '';
    },
    handleInputChange(inputName, value) {
      this.newListing = value;
    },
    handleKeyUp(event, inputName) {
      this.handleInputChange(inputName, event.target.value);
      event.keyCode === 13 && this.handleAddNewListing();
    }
  }
}
</script>

<style scoped>

</style>
