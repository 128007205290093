import { mapActions, mapGetters } from 'vuex';

export const Attachments = {
    data() {
        return {
            formData: new FormData()
        }
    },
    computed: {
      ...mapGetters([
        'createAttachmentFileSuccess',
        'createAttachmentFileError',
        'attachmentFileResponseMessage',
        'attachedFilesList',
        'deleteAttachmentFileSuccess',
        'deleteAttachmentFileError',
        'createGoogleFileSuccess',
        'createGoogleFileError',
        'attachedGoogleFiles',
        'deleteGoogleFileSuccess',
        'deleteGoogleFileError'
      ])
    },
    watch: {
        createAttachmentFileSuccess(newValue) {
            if (newValue && this.form) {
                this.toastMessage(this.attachmentFileResponseMessage, 'success');
                this.form.files = this.attachedFilesList;
            }
        },
        createAttachmentFileError(newValue) {
            newValue && this.toastMessage(this.attachmentFileResponseMessage, 'error');
        },
        deleteAttachmentFileSuccess(newValue) {
            newValue && this.toastMessage(this.attachmentFileResponseMessage, 'success');
        },
        deleteAttachmentFileError(newValue) {
            newValue && this.toastMessage(this.attachmentFileResponseMessage, 'error')
        },
        createGoogleFileSuccess(newValue) {
            if (newValue) {
                this.toastMessage(this.attachmentFileResponseMessage, 'success');
                this.form.google_files = this.attachedGoogleFiles;
            }
        }
    },
    methods: {
        ...mapActions({
            handleCreateAttachmentFile: 'createAttachmentFile',
            handleFetchDeleteAttachmentFile: 'deleteAttachmentFile',
            handleCreateGoogleAttachmentFile: 'createGoogleAttachmentFile',
            handleDeleteGoogleAttachmentFile: 'deleteGoogleAttachmentFile'
        }),
        handleAddAttachments(files) {
            this.combineData(files);
            this.pageUpdateAction ? this.handleCreateAttachmentFile({
                prefix: this.prefix,
                ownerId: this.form.id,
                form: this.formData
            }) : this.form.files = [...this.form.files, ...files];
        },
        combineData(files) {
            Object.keys(files).filter((file, index) => this.formData.append(`file[${index}]`, files[index]));
        },
        deleteAttachmentFile({ selectedAttachmentFile }) {
            const files = Array.from(this.form.files);
            this.form.files = files.filter(file => file.name !== selectedAttachmentFile.name);
            this.pageUpdateAction && this.fetchDeleteFile({ prefix: this.prefix, ownerId: this.form.id, id: selectedAttachmentFile.id });
        },
        fetchDeleteFile({ prefix, ownerId, id }) {
            this.handleFetchDeleteAttachmentFile({ prefix, ownerId, id });
        },
        handleAddGoogleAttachments(files) {
            this.combineData(files);
            this.pageUpdateAction ? this.handleCreateGoogleAttachmentFile({
                prefix: this.prefix,
                ownerId: this.form.id,
                form: files
            }) : this.form.google_files = [...this.form.google_files, ...files];
        },
        fetchDeleteAddGoogleFile({ prefix, ownerId, id }) {
            this.handleDeleteGoogleAttachmentFile({ prefix, ownerId, id });
        },
        deleteGoogleAttachmentFile({ selectedAttachmentFile }) {
            const files = Array.from(this.form.google_files);
            this.form.google_files = files.filter(file => file.id !== selectedAttachmentFile.id);
            this.pageUpdateAction && this.fetchDeleteAddGoogleFile({ prefix: this.prefix, ownerId: this.form.id, id: selectedAttachmentFile.id });
        },
        toastMessage(message, type) {
            this.$toast.clear();
            this.$toast.open({ message, type });
        }
    }
}
