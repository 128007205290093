<template>
    <div class="grid grid-cols-3  ">
        <div class="relative flex justify-end items-center">
            <EditDeleteBoardDropDown/>
        </div>
        <div class="relative">
            <CreateNewListingDropDown/>
        </div>
        <div class="relative">
            <CreateNewTaskDropDown/>
        </div>
    </div>
</template>

<script>
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import ClickOutside from 'vue-click-outside';
import CreateNewListingDropDown
    from '@/components/DropDowns/CreateTaskActions/CreateNewListingDropDown/CreateNewListingDropDown';
import CreateNewTaskDropDown
    from '@/components/DropDowns/CreateTaskActions/CreateNewTaskDropDown/CreateNewTaskDropDown';
import EditDeleteBoardDropDown from '@/components/DropDowns/Boards/EditDeleteBoardDropDown';

export default {
    name: 'CreateTaskActions',
    components: {
        EditDeleteBoardDropDown,
        CreateNewTaskDropDown,
        CreateNewListingDropDown,
        MainButton
    },
    directives: {
        ClickOutside
    }
};
</script>

<style scoped>

</style>
