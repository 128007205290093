import { mapActions } from 'vuex';

export const withTaskCheckList = {
    methods: {
        ...mapActions([
            'fetchDeleteCheckList',
            'fetchCreateCheckList',
            'fetchUpdateCheckList',
            'fetchCreateCheckListItem',
            'fetchDeleteCheckListItem',
            'fetchUpdateCheckListItem',
            'fetchUpdateCheckListItemStatus'
        ]),
        handleDeleteCheckListItem({ item, checkListId }) {
            const filteredCheckList = this.form.checklists.find(checkList => checkList.id === checkListId);
            filteredCheckList.items = filteredCheckList.items.filter(listItem => listItem.id !== item.id);
            filteredCheckList.items_total -= 1;
            item.done ? filteredCheckList.items_done -= 1 : null;
            this.fetchDeleteCheckListItem({ id: item.id })
                .then(({ data: { message } }) => this.toastMessage(message));
        },
        handleDeleteCheckList(itemId) {
            this.form.checklists = this.form.checklists.filter(checkList => checkList.id !== itemId);
            this.fetchDeleteCheckList({ id: itemId }).then(({ data: { message } }) => this.toastMessage(message));
        },
        handleAddNewCheckList(title) {
            this.fetchCreateCheckList({ form: { id: this.form.id, title } })
                .then(({ data: { message, data } }) => {
                    this.form.checklists.push(data);
                    this.toastMessage(message);
                });
        },
        handleCreateNewCheckListItem({ item, checkListId }) {
            const filteredCheckList = this.form.checklists.find(checkList => checkList.id === checkListId);
            this.fetchCreateCheckListItem({ form: { id: checkListId, item } }).then(({ data: { message, data } }) => {
                filteredCheckList.items_total += 1;
                filteredCheckList.items.push(data);
                this.toastMessage(message);
            });
        },
        handleUpdateCheckList({ id, value }) {
            this.fetchUpdateCheckList({ form: { id, title: value } }).then((data) => this.handleToastMessage(data));
        },
        handleUpdateCheckListItem({ id, value }) {
            this.fetchUpdateCheckListItem({ form: { id, item: value } }).then((data) => this.handleToastMessage(data));
        },
        handleToastMessage(data) {
            data.status !== 200 ? this.toastMessage(data.data.message, 'error') : this.toastMessage(data.data.message);
        },
        handleEditCheckListItemStatus({ item, checkListId }) {
            const { status, id } = item;
            const filteredCheckList = this.form.checklists.find(checkList => checkList.id === checkListId);
            status ? filteredCheckList.items_done -= 1 : filteredCheckList.items_done += 1;
            this.fetchUpdateCheckListItemStatus({ form: { id, done: !status } }).then((data) => this.handleToastMessage(data));
        },
        toastMessage(message, type = 'success') {
            this.$toast.clear();
            this.$toast.open({ message, type });
        }
    }
}
