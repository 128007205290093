import { mapActions, mapGetters } from 'vuex';
import { EventBus } from '@/eventBus/event-bus';

export const withBreadCrumbs = {
    data() {
        return {
            breadcrumbArray: [],
            editableBreadCrumb: {},
            isEditable: false,
            editableInputValue: ''
        }
    },
    computed: {
        ...mapGetters([
            'getBreadCrumb', 'getBreadCrumbItems'
        ])
    },
    watch: {
        getBreadCrumb() {
            this.breadCrumb();
        },
        getBreadCrumbItems() {
            this.breadCrumb();
        },
        $route() {
            this.breadCrumb();
            this.editableBreadCrumb = {};
            this.editableInputValue = '';
        }
    },
    mounted() {
        EventBus.$on('handleEditBreadCrumbItem', () => {
            if (!Object.keys(this.editableBreadCrumb).length) {
                this.editableBreadCrumb = this.breadcrumbArray.pop();
                this.editableInputValue = this.editableBreadCrumb.text;
                process.nextTick(() => this.$refs.editableInput.focus());
            }
            this.isEditable = false;
        });
        this.breadCrumb();
    },
    methods: {
        ...mapActions({ handleUpdateBoardTitle: 'fetchUpdateBoard' }),
        breadCrumb() {
            const route = this.$route;
            const pathArray = route.path.split('/');
            pathArray.shift();
            pathArray.length > 2 && pathArray.pop();
            pathArray.length >= 3 && pathArray.pop();
            this.breadcrumbArray = pathArray.reduce((breadcrumbArray, path, idx) => {
                breadcrumbArray.push({
                    path: path,
                    to: typeof this.getBreadCrumbItems[idx]?.to !== 'undefined' ? this.getBreadCrumbItems[idx]?.to : (breadcrumbArray[idx] ? '/' + breadcrumbArray[idx - 1].path + '/' + path : '/' + path),
                    text: typeof this.getBreadCrumbItems[idx]?.name !== 'undefined' ? this.getBreadCrumbItems[idx]?.name : (this.$t(route.matched[idx].meta?.breadCrumb) || this.getBreadCrumb),
                    disabled: route.matched[idx].meta?.breadCrumbDisable,
                    hidden: this.getBreadCrumbItems[idx]?.hidden || route.matched[idx].meta?.breadCrumbHidden
                });

                return breadcrumbArray;
            }, []);
            // console.log(this.breadcrumbArray);
        },
        handleInputChange(event) {
            const { id } = this.$route.params;
            if (event.target.value.length) {
                this.handleUpdateBoardTitle({ id, form: { title: event.target.value } }).then(({ data }) => {
                    this.editableBreadCrumb.text = event.target.value;
                    this.breadcrumbArray.push(this.editableBreadCrumb);
                    this.editableBreadCrumb = {};
                    this.toastMessage(data.message);
                });
            } else {
                this.editableInputValue = this.editableBreadCrumb.text;
            }
        },
        toastMessage(message, type = 'success') {
            this.$toast.open({ message, type });
        }
    }
}
