import { mapActions, mapGetters } from 'vuex';
import { makeFormData } from '@/helpers/formData';


export const RequestTaskForm = {
    computed: {
        ...mapGetters([
            'task',
            'createTaskSuccess',
            'updateTaskError',
            'createTaskError',
            'taskResponseMessage'
        ])
    },
    watch: {
        createTaskSuccess(newValue) {
            if (newValue) {
                this.taskToastMessage(this.taskResponseMessage);
                this.redirectToShowPage();
            }
        },
        createTaskError(newValue) {
            newValue && this.handleRequestErrorMessage(newValue);
        },
        updateTaskError(newValue) {
            newValue && this.handleRequestErrorMessage(newValue);
        }
    },
    methods: {
        ...mapActions({
            handleUpdateTask: 'updateTask',
            handleCreateTask: 'createTask',
            fetchCreateLink:'addLink',
            fetchUpdateLink:'updateLink',
            fetchDelateLink:'deleteLink'
        }),
        createRequestFormData() {
            this.form.links.map(link => {
                return {
                    url: link.url,
                    name: link.name
                }
            })
            return makeFormData({
                file: this.form.files,
                title: this.form.title,
                description: this.form.description,
                listing_id: this.form.listing_id,
                due: this.form.due,
                customer_id: this.form.customer_id,
                apartments: this.form.apartments.map(apartment => apartment.id),
                links: JSON.stringify(this.form.links.map(link => {
                    return {
                        url: link.url,
                        name: link.name
                    }
                }))
            });
        },
        redirectToShowPage() {
            this.$router.push({ name: 'task-show', params: { taskId: this.task.id } });
        },
        createTask() {
            const formData = this.createRequestFormData();
            this.form.members.filter((member, index) => formData.append(`members[${index}]`, this.form.members[index].id));
            this.form.labels.filter((label, index) => formData.append(`labels[${index}]`, this.form.labels[index].id));
            this.form.google_files.filter((googleFile, index) => formData.append(`googleFile[${index}]`, this.form.google_files[index].url));
            this.form.contacts.filter((contact, index) => {
                formData.append(`contacts[${index}][name]`, this.form.contacts[index].name);
                formData.append(`contacts[${index}][email]`, this.form.contacts[index].email);
                formData.append(`contacts[${index}][phone]`, this.form.contacts[index].phone);
                formData.append(`contacts[${index}][role]`, this.form.contacts[index].role);
            });
            this.handleCreateTask({ form: formData });
        },
        handleAddNewLink({ url, name }) {
            this.fetchCreateLink({ id:this.form.id, form: { url, name } }).then(() => {});
        },
        handleEditLink(link) {
            this.fetchUpdateLink({ id:this.form.id, link: link }).then(() => {});
        },
        handleDeleteLink(link) {
            this.fetchDelateLink({ id:this.form.id, linkId:link }).then(() => {});
        },
        updateTask() {
            this.handleUpdateTask({ form: this.form, id: this.form.id });
        },
        handleRequestErrorMessage(isError) {
            if (isError) {
                const { message, errors } = this.taskResponseMessage;
                this.formErrors = errors;
                this.taskToastMessage(message, 'error');
            }
        },
        handleInputFieldChange({ value, inputName }) {
            this.form[inputName] = value;
        },
        handleInputChange(inputName, value) {
            this.formErrors[inputName] = [];
            this.form[inputName] = value;
        },
        taskToastMessage(message, type = 'success') {
            this.$toast.clear();
            this.$toast.open({ message, type })
        }
    }
}
