<template>
  <div v-click-outside="closeDropdown" class="relative">
    <div>
      <MainButton
          :name="$t('invoice.actions')"
          @clickAction="isOpen = !isOpen"
      />
    </div>
    <div v-if="isOpen" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
      <div class="py-1">
        <a @click="sendInvoiceTransmitAll" class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
          {{ $t('dropDown.addInvoiceableItems') }}
        </a>
        <SendSelectedInvoicesModal
            v-if="showModal"
            :title="$t('checkList.sendCheckListModal.title')"
            :description="$t('checkList.sendCheckListModal.description')"
            @handleCloseModal="showModal = false"
            @handleConfirm="sendSelectedInvoices"
        />
        <a @click="openDeleteCheckListModal" class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
        >
          {{ $t('dropDown.sendAllSelectedInvoices') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import DeleteItemModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';
import { EventBus } from '@/eventBus/event-bus';
import { mapActions } from 'vuex';
import SendSelectedInvoicesModal from '@/components/Modals/ConfirmModal/SendSelectedInvoicesModal';

export default {
  name: 'SendInvoiceDropDown',
  components: {
    SendSelectedInvoicesModal,
    MainButton,
    DeleteItemModal
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      showModal: false,
      isOpen: false
    }
  },
  methods: {
    ...mapActions([
      'fetchInvoiceTransmitAll'
    ]),
    closeDropdown() {
      this.isOpen = false;
    },
    sendSelectedInvoices() {
      EventBus.$emit('sendSelectedInvoices');
      this.closeDropdown();
    },
    sendInvoiceTransmitAll() {
      this.fetchInvoiceTransmitAll().then(({ data: { message } }) => this.toastMessage(message));
      this.closeDropdown();
    },
    toastMessage(message, type = 'success') {
      this.$toast.clear();
      this.$toast.open({ message, type });
    },
    handleSetSelectedItem(id) {
      this.selectedItem = id;
    },
    openDeleteCheckListModal(id) {
      this.showModal = true;
      this.selectedItem = id
    },
    handleDeleteItem() {
      this.showModal = false;
      this.$emit('handleDeleteCheckList', this.selectedItem);
    }
  }
}
</script>

<style scoped>

</style>
