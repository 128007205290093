<template>
    <div class="relative inline-block text-left" v-click-outside="closeDropdown">
        <DeleteItemModal
            v-if="showDeleteModal"
            :title="$t('board.deleteBoardModal.title')"
            :description="$t('board.deleteBoardModal.description')"
            @handleCloseModal="showDeleteModal = false"
            @handleConfirm="deleteBoard"
        />

        <div @click="isOpen = !isOpen">
            <SettingsIcon/>
        </div>
        <div v-if="isOpen"
             class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
            <div class="py-1" role="none">
                <a href="#" @click.prevent="handleRenameBoard"
                   class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                    {{ $t('global.settingsBoard.renameBoard') }}
                </a>
            </div>
            <div class="py-1" role="none">
                <a href="#" @click.prevent="handleDeleteBoard"
                   class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                    {{ $t('global.deleteBoard') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import SettingsIcon from '@/components/Icons/SettingsIcon';
import DeleteItemModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';
import ClickOutside from 'vue-click-outside';
import { EventBus } from '@/eventBus/event-bus';
import { mapActions } from 'vuex';

export default {
    name: 'EditDeleteBoardDropDown',
    components: { SettingsIcon, DeleteItemModal },
    directives: {
        ClickOutside
    },
    data() {
        return {
            isOpen: false,
            showDeleteModal: false
        };
    },
    methods: {
        ...mapActions(['fetchDeleteBoard']),

        closeDropdown() {
            this.isOpen = false;
        },
        handleRenameBoard() {
            EventBus.$emit('handleEditBreadCrumbItem', true);
            this.isOpen = false;
        },
        handleDeleteBoard() {
            this.showDeleteModal = true;
            this.isOpen = false;
        },
        deleteBoard() {
            const { id } = this.$route.params;
            this.fetchDeleteBoard({ id }).then(({ data }) => {
                this.toastMessage(data.message);
                this.$router.push('/boards');
            });
        },
        toastMessage(message, type = 'success') {
            this.$toast.open({ message, type });
        }
    }
};
</script>

<style scoped>

</style>
