<template>
    <div class="relative z-10 flex-shrink-0 flex h-16 bg-white lg:hidden" v-click-outside="closeDropdown">
        <button
            @click="$emit('handleToggleSideBar')"
            class="px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden">
            <span class="sr-only">Open sidebar</span>
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M4 6h16M4 12h8m-8 6h16"/>
            </svg>
        </button>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { mapGetters } from 'vuex';

export default {
  name: 'SearchHeader',
  directives: {
    ClickOutside
  },
  computed: {
    ...mapGetters(['account'])
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    handleToggleDropDown(e) {
      e.preventDefault();
      this.isOpen = !this.isOpen;
    },
    handleLogOut() {
      this.$store.dispatch('userLogOut');
    }
  }
}
</script>

<style scoped>

</style>
