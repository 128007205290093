<template>
  <div class="relative inline-block text-left" v-click-outside="closeDropdown">
    <div>
      <MainButton
          :name="$t('calendar.actionsDropDown.title')"
          @clickAction="isOpen = !isOpen"
      />
    </div>
    <div v-if="isOpen"
         class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
      <div class="py-1" role="none">
        <a v-if="getUserCalendarExist&&accessToken" @click="handleSyncEvents"
           class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
          {{ $t('global.buttons.syncEvents') }}
        </a>
        <a v-if="getUserCalendarExist&&accessToken" @click="handleDeleteEvents"
           class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
          {{ $t('global.buttons.deleteEvents') }}
        </a>
        <div v-show="!getUserCalendarExist||!accessToken" class="w-full flex justify-start">
          <GoogleLogin
              class="w-full cursor-pointer text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              :params="params" :onSuccess="onSuccess" :onFailure="onFailure" :onCurrentUser="updateCurrentUser">{{ $t('global.buttons.syncEvents') }}
          </GoogleLogin>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import { mapActions, mapGetters } from 'vuex';
import ClickOutside from 'vue-click-outside';
import GoogleLogin from 'vue-google-login';
import axios from 'axios';


export default {
  name: 'CalendarEventsDropDown',
  components: {
    MainButton,
    GoogleLogin
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      isOpen: false,
      params: {
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        scope: process.env.VUE_APP_GOOGLE_SCOPE,
        prompt: 'select_account',
        include_granted_scopes: true
      },
      accessToken:null
    }

  },
  created() {
    this.userCalendarEvents()
    this.getAccessToken()
  },
  computed: {
    ...mapGetters([
      'getUserCalendarExist', 'syncCalendarEventsError'
    ])
  },
  watch: {
    syncCalendarEventsError(){
      this.getAccessToken()
    }
  },
  methods: {
    ...mapActions({
      syncCalendarEvents: 'syncCalendarEvents',
      userCalendarEvents: 'userCalendarEvents',
      handleUpdateCalendarId: 'updateCalendarId',
      fetchGoogleCalendarEvents: 'fetchGoogleCalendarEvents',
      deleteCalendarEvents: 'deleteCalendarEvents'
    }),
    toastMessage(message, type = 'success') {
      this.$toast.clear();
      this.$toast.open({ message, type })
    },
    updateCurrentUser(googleUser){
      const accessToken = googleUser.getAuthResponse().access_token
      localStorage.accessToken = accessToken
    },
    getAccessToken(){
      this.accessToken = localStorage.accessToken ?? null
    },
    handleSyncEvents() {
      //old sync functionality to sync from back
      // this.syncCalendarEvents
      this.getAccessToken()
      this.fetchGoogleCalendarEvents(this.accessToken)
      this.closeDropdown();
    },
    handleDeleteEvents() {
      this
        .deleteCalendarEvents()
        .then(() => {
          this.toastMessage('Events deleted successfully', 'success');
        });

      this.closeDropdown();
    },
    handleDeleteEvent(id) {},
    closeDropdown() {
      this.isOpen = false;
    },
    onSuccess(googleUser) {
      const profile = googleUser.getBasicProfile()
      const email = profile.getEmail()
      const accessToken = googleUser.getAuthResponse().access_token
      localStorage.accessToken = accessToken
      this.accessToken = accessToken
      this.$store.commit('userCalendarExist', email)
      this.handleUpdateCalendarId({ google_calendar_id: email })
      this.fetchGoogleCalendarEvents(accessToken)
      this.closeDropdown();
    },
    onFailure(event) {
      console.log('event', event);
    }
  }
}
</script>

<style scoped>

</style>
