<template>
  <div class="lg:hidden">
    <div class="fixed inset-0 flex z-40">
      <div
          @click="$emit('handleToggleSideBar')"
          class="fixed inset-0"
          aria-hidden="true">
        <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
      </div>
      <div
          class="relative flex-1 flex flex-col max-w-xs w-full pt-5 transition ease-in-out duration-300 transform pb-4 bg-white">
        <div class="absolute top-0 right-0 -mr-12 pt-2">
          <button
              class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              @click="$emit('handleToggleSideBar')"
          >
            <span class="sr-only">{{ $t('layout.closeSidebar') }}</span>
            <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </button>
        </div>
        <div class="flex-shrink-0 flex items-center px-4">
          <BaseLogo />
        </div>
        <div class="mt-5 flex-1 h-0 overflow-y-auto">
          <nav class="px-3 mt-6">
            <div class="space-y-1">
              <RouterLinks :routeNavigation="routeNavigation" />
            </div>
            <Teams />
          </nav>
        </div>
        <AccountDropDown />
      </div>
      <div class="flex-shrink-0 w-14" aria-hidden="true">
      </div>
    </div>
  </div>
</template>

<script>
import RouterLinks from '@/components/Main/RouterLinks/RouterLinks';
import Teams from '@/components/Layout/Labels/Labels';
import AccountDropDown from '@/components/DropDowns/AccountDropDown/AccountDropDown';
import BaseLogo from '@/components/BaseLogo/BaseLogo';

export default {
  name: 'MobileSideBar',
  components: {
    BaseLogo,
    RouterLinks,
    Teams,
    AccountDropDown
  },
  props: {
    routeNavigation: Array
  }
}
</script>

<style scoped>

</style>
