<template>
  <div class="mt-8" v-if="labels.length > 0">
    <h3 class="px-3 text-xs font-semi bold text-gray-500 uppercase tracking-wider"
        id="teams-headline">
      <span class="truncate ml-3"> {{ $t('global.teamTypes.labels') }}</span>
    </h3>
    <div class="mt-1 space-y-1" role="group" aria-labelledby="teams-headline" >
      <router-link @click.native="getLabelsTasks(label.id)" v-for="label in labels" :key="label.id" :to="{ name: 'label-tasks', params: { id: label.id } }"
         class="text-gray-700 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:bg-gray-50 ">
        <span class="w-2.5 h-2.5 mr-4  rounded-full" :class="label.color" aria-hidden="true"></span>
        <span class="truncate ml-3"> {{label.title}}</span>
      </router-link>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Labels',
  methods: {
    ...mapActions(['getLabels', 'fetchLabelsTasks']),
    getLabelsTasks(labelId) {
      this.fetchLabelsTasks(labelId)
    }
  },
  computed: {
    ...mapGetters(['labels'])
  },
  mounted() {
    this.getLabels();
  }
}
</script>

<style scoped>

</style>
