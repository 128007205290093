<template>
  <div class="flex w-full" aria-label="Breadcrumb">
    <ol class="flex items-center space-x-1">
      <li>
        <div class="flex items-center">
          <routerLink to="/boards" class="text-sm font-medium text-gray-500 hover:text-gray-700">
            {{ $t('global.home') }}
          </routerLink>
        </div>
      </li>
      <li v-for="crumb in breadcrumbArray" v-if="!crumb.hidden">
        <div class="flex items-center">
          <svg class="flex-shrink-0 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
          </svg>
          <router-link v-if="!!crumb.to" :event="!crumb.disabled ? 'click' : ''" :to="crumb.to">
            <div class="text-sm font-medium flex text-gray-500 hover:text-gray-700 whitespace-nowrap">{{ crumb.text }}</div>
          </router-link>
          <div v-else class="text-sm font-medium flex text-gray-500 hover:text-gray-700 whitespace-nowrap cursor-pointer">{{ crumb.text }}</div>
        </div>
      </li>
      <li v-if="Object.keys(editableBreadCrumb).length">
        <div class="flex">
          <svg class="flex-shrink-0 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
          </svg>
          <div class="w-420p">
            <input
                class="w-full h-auto border-0 overflow-y-hidden text-sm text-gray-900 outline-none focus:ring-0 p-0"
                v-model="editableInputValue"
                ref="editableInput"
                autofocus="autofocus"
                @change="handleInputChange"
            />
          </div>
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
import { withBreadCrumbs } from '@/mixins/withBreadCrumbs';

export default {
  name: 'Breadcrumbs',
  mixins: [withBreadCrumbs]
}
</script>

<style scoped>

</style>
