<template>
  <nav class="px-3 mt-6">
    <div class="space-y-1">
        <RouterLinks :routeNavigation="routeNavigation"/>
    </div>
    <Labels/>
  </nav>
</template>

<script>
import RouterLinks from '@/components/Main/RouterLinks/RouterLinks';
import Labels from '@/components/Layout/Labels/Labels';
import { mapGetters } from 'vuex';

export default {
  name: 'NavBar',
  components: {
    RouterLinks,
    Labels
  },
  props: {
    routeNavigation: Array
  },
    computed: {
    ...mapGetters(['account'])
  }
}
</script>

<style scoped>

</style>
