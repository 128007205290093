<template>
  <div v-if="account" class="px-3 mt-6 relative inline-block text-left" v-click-outside="closeDropdown">
    <div v-if="openDropDown" class="flex flex-wrap">
      <div class="w-full">
        <div class="relative inline-flex align-middle w-full">
          <div class="w-full bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mb-1" style="min-width:12rem">
            <div class="" role="none">
              <router-link to="/account" @click.native="closeDropdown" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                {{ $t('global.accountDropDown.viewProfile') }}
              </router-link>
            </div>
            <div class="" role="none">
              <router-link v-if="account ? account.isadmin :''"  to="/users" @click.native="closeDropdown" class="block px-4 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                </router-link>
              <router-link to="/invoice-connectors" @click.native="closeDropdown" v-if="account.isadmin" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                {{ $t('global.accountDropDown.invoiceConnectors') }}
              </router-link>
            </div>
            <div class="" role="none">
              <router-link v-if="account?account.isadmin:''"  to="/users" @click.native="closeDropdown" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                {{ $t('global.accountDropDown.userManagement') }}
              </router-link>
            </div>
            <div class="" role="none">
              <router-link v-if="account ? account.isadmin :''"  to="/labels" @click.native="closeDropdown" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
               {{ $t('global.labels')}}
              </router-link>
            </div>
            <div class="" role="none">
              <a href="#" @click.prevent="handleLogOut" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                {{ $t('global.accountDropDown.logOut') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <button type="button"
              class="group w-full bg-gray-100 rounded-md px-3.5 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none"
              id="options-menu"
              aria-expanded="false"
              aria-haspopup="true"
              @click.prevent="handleToggleDropDown"
      >
              <span class="flex w-full justify-between items-center">
                <span class="flex min-w-0 items-center justify-between space-x-3">
                  <img v-if="account.avatar" class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                       :src="account.avatar"
                       alt="" />
                  <div v-else>
                    <div class="h-9 w-9 rounded-full bg-gray-200 flex items-center">
                      <span class="block w-full">{{ account.name | getNameAbbr }}</span>
                    </div>
                  </div>
                  <span class="flex-1 min-w-0 text-left overflow-hidden">
                    <span class="text-gray-900 text-sm font-medium truncate">{{ account.name }}</span>
                      <br>
                    <span class="text-gray-500 text-sm truncate block">{{ account.email }}</span>
                  </span>
                </span>
                <div class="absolute right-2">
                   <FormInputArrowIcon />
                </div>
              </span>
      </button>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { mapActions, mapGetters } from 'vuex';
import FormInputArrowIcon from '@/components/Icons/FormInputArrowIcon';

export default {
  name: 'AccountDropDown',
  components: { FormInputArrowIcon },
  directives: {
    ClickOutside
  },
  data() {
    return {
      openDropDown: false
    }
  },
  computed: {
    ...mapGetters(['account']),
    ...mapGetters(['users'])
  },
  methods: {
    ...mapActions({ handleUserLogOut: 'userLogOut' }),
    closeDropdown() {
      this.openDropDown = false;
    },
    handleToggleDropDown() {
      this.openDropDown = !this.openDropDown;
    },
    handleLogOut() {
      this.handleUserLogOut();
    }
  }
}
</script>

<style scoped>

</style>
