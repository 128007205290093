<template>
  <div class="h-screen flex overflow-hidden bg-white">
    <MobileSideBar
        v-if="isOpenMobileSideBar"
        :routeNavigation="routeNavigation"
        @handleToggleSideBar="handleToggleSideBar"
    />
    <DescTopSideBar :routeNavigation="routeNavigation" />
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <SearchHeader @handleToggleSideBar="handleToggleSideBar" />
      <main class="flex-1 relative z-0 overflow-x-hidden focus:outline-none">
        <Header />
        <slot />
      </main>
    </div>
  </div>
</template>

<script>
import DescTopSideBar from './DescTopSideBar/DescTopSideBar.vue';
import SearchHeader from './SearchHeader/SearchHeader.vue';
import Header from './Header/Header.vue';
import MobileSideBar from './MobileSideBar/MobileSideBar.vue';
import appLayoutDefaultNavigation from '@/helpers/appLayoutDefaultNavigation';
import { withAppLayout } from '@/mixins/withAppLayout';

export default {
  mixins:[withAppLayout],
  name: 'AppLayout',
  components: {
    DescTopSideBar,
    SearchHeader,
    Header,
    MobileSideBar
  },
  data() {
    return {
      routeNavigation: [...appLayoutDefaultNavigation]
    }
  }
}
</script>

<style scoped>

</style>
