<template>
  <div>
    <div v-for="navigation in routeNavigation" :key="navigation.id">
      <router-link
          v-if="navigation.id!=8"
          :to="navigation.path"
          :class="['text-gray-700 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md', {
            'bg-gray-200 text-gray-900': navigation.pathname === activeRoute,
            'text-gray-400 hover:bg-gray-50': navigation.pathname !== activeRoute
          }]">
        <img :src="require(`../../../assets/icons/${navigation.icon}.svg`)" alt="" class="mr-3">
        {{ $t(navigation.name) }}
      </router-link>
      <router-link
          v-if="navigation.id==8 && isadmin"
          :to="navigation.path"
          :class="['text-gray-700 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md', {
            'bg-gray-200 text-gray-900': navigation.pathname === activeRoute,
            'text-gray-400 hover:bg-gray-50': navigation.pathname !== activeRoute
          }]">
        <img :src="require(`../../../assets/icons/${navigation.icon}.svg`)" alt="" class="mr-3">
        {{ $t(navigation.name) }}
      </router-link>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'RouterLinks',
  props: {
    routeNavigation: {
      type: Array,
      required: true
    },
    isadmin: {
      type: Boolean
    }
  },
  data() {
    return {
      activeRoute: ''
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.activeRoute = route.meta.staticPathName;
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['account'])
  }
}
</script>

<style scoped>

</style>
