
export default [
    {
        id: 1,
        path: '/home',
        name: 'menu.home',
        pathname: 'home',
        icon: 'home'
    },
    {
        id: 2,
        path: '/boards',
        name: 'menu.boards',
        pathname: 'boards',
        icon: 'tasks'
    },
    {
        id: 3,
        path: '/my-tasks',
        name: 'menu.myTasks',
        pathname: 'my-tasks',
        icon: 'myTasks'
    },
    {
        id: 4,
        path: '/calendar',
        name: 'menu.calendar',
        pathname: 'calendar',
        icon: 'calendar'
    },
    {
        id: 5,
        path: '/invoices',
        name: 'menu.invoices',
        pathname: 'invoices',
        icon: 'invoices'
    },
    {
        id: 6,
        path: '/customers',
        name: 'menu.customers',
        pathname: 'customers',
        icon: 'customers'
    },
    {
        id: 7,
        path: '/products',
        name: 'menu.products',
        pathname: 'products',
        icon: 'products'
    },
    {
        id: 8,
        path: '/labels',
        name: 'menu.labels',
        pathname: 'labels',
        icon: 'products'
    }
    // {
    //     id: 9,
    //     path: '/invoice-connectors',
    //     name: 'menu.invoiceConnectors',
    //     pathname: 'invoiceConnectors',
    //     icon: 'products'
    // }
];
