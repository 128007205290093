<template>
  <div class="relative inline-block text-left" v-click-outside="closeDropdown">
    <div>
      <MainButton
          :name="$t('global.buttons.add_new')"
          @clickAction="isOpen = !isOpen"
      />
    </div>
    <div v-if="isOpen" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
      <div class="py-1" role="none">
        <a href="#" class="block px-4 py-2" role="menuitem" tabindex="-1" id="menu-item-0">
          <FormInput
              :value="newBoard"
              @handleInputChange="handleInputChange"
          />
        </a>
        <a href="#" class="block px-4 py-2" role="menuitem" tabindex="-1" id="menu-item-1">
          <MainButton
              :name="$t('global.buttons.add')"
              @clickAction="handleCreateNewBoard"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import ClickOutside from 'vue-click-outside';
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import { mapActions } from 'vuex';
import { EventBus } from '@/eventBus/event-bus';

export default {
  name: 'CreateBoardDropDown',
  components: {
    FormInput,
    MainButton
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      isOpen: false,
      newBoard: ''
    }
  },
  methods: {
    ...mapActions({ handleCreateBoard: 'fetchCreateBoard' }),
    closeDropdown() {
      this.isOpen = false;
    },
    handleInputChange(inputName, value) {
      this.newBoard = value;
    },
    handleCreateNewBoard() {
      this.newBoard.length && this.handleCreateBoard({ title: this.newBoard });
      this.closeDropdown();
      this.newBoard = '';
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type })
    }
  }
}
</script>

<style scoped>

</style>
