/**
 * @param {Record<string, File | string | (string | File)[]>} source
 * @return {FormData}
 */
export const makeFormData = (source) => {
    const data = new FormData();
    for(const key in source) {
        if (Array.isArray(source[key])) {
            for(let i = 0; i < source[key].length; i++) {
                data.append(`${key}[${i}]`, source[key][i]);
            }
        } else {
            data.append(key, source[key]);
        }
    }

    return data;
}
