<template>
  <div class="flex items-center justify-start gap-1">
    <div v-if="$route.meta.customEdit">
      <MainButton
          @clickAction="handleToggleEditClose(true)"
          :name="$t('global.buttons.edit')"
      />
    </div>
    <template v-else>
      <div v-if="editable" class="mr-3">
        <MainButton
            @clickAction="handleToggleEditClose(false)"
            :name="$t('global.cancel')"
            :bgStyle="'light'"
        />
      </div>
      <div v-if="!editable">
        <MainButton
            @clickAction="handleToggleEditClose(true)"
            :name="$t('global.buttons.edit')"
        />
      </div>
      <div v-else>
        <MainButton
            @clickAction="handleSave"
            :name="$t('global.buttons.save')"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { EventBus } from '@/eventBus/event-bus';
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import { Attachments } from '@/mixins/Attachments';
import { RequestTaskForm } from '@/mixins/Task/RequestTaskForm';
import { withTaskCheckList } from '@/mixins/Task/withTaskCheckList';

export default {
  name: 'SaveEditActionButtons',
  mixins: [Attachments, RequestTaskForm, withTaskCheckList],
  components: {
    MainButton
  },
  data() {
    return {
      editable: false,
      isFormSubmitted: false
    }
  },
  watch: {
    updateTaskError() {
      this.editable = true;
    }
  },
  mounted() {
    EventBus.$on('formSubmitted', (isFormSubmitted) => {
      this.editable = !isFormSubmitted;
      isFormSubmitted ? this.editable = false : true;
      this.$emit('handleEdit', false);
    });
  },
  methods: {
    handleToggleEditClose(editable) {
      this.editable = !this.editable;
      this.$emit('handleEdit', editable);
    },
    handleSave() {
      EventBus.$emit('handleSave');
    }
  }
}
</script>

<style scoped>

</style>
