
export const withAppLayout = {
    data() {
      return {
          isOpenMobileSideBar: false
      }
    },
    methods: {
        handleToggleSideBar() {
            this.isOpenMobileSideBar = !this.isOpenMobileSideBar;
        }
    }
}
