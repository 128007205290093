<template>
  <div class="hidden lg:flex lg:flex-shrink-0">
    <div class="flex flex-col w-64 border-r border-gray-200 pt-5 pb-4 bg-gray-100">
      <div class="flex items-center flex-shrink-0 px-6">
        <BaseLogo/>
      </div>
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="h-0 flex-1 flex flex-col overflow-y-auto">
        <!-- Navigation -->
        <NavBar :routeNavigation="routeNavigation" />
      </div>
      <AccountDropDown/>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/Layout/NavBar/NavBar';
import AccountDropDown from '@/components/DropDowns/AccountDropDown/AccountDropDown';
import BaseLogo from '@/components/BaseLogo/BaseLogo';

export default {
  name: 'DescTopSideBar',
  components: {
    BaseLogo,
    NavBar,
    AccountDropDown
  },
  props: {
    routeNavigation: Array
  }
}
</script>

<style scoped>

</style>
