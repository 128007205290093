<template>
  <div>
  <div class="grid sm:grid-cols-1 xs:grid-cols-2 testClass">
    <div class="relative rounded-lg bg-white px-7 py-8 flex items-center ">
      <Breadcrumbs/>
    </div>
    <div class="relative rounded-lg bg-white xs:px-6 py-5 flex items-center sm:justify-start xs:justify-end space-x-3  mobile:space-x-12 justify-end">
      <MainButton
          v-if="this.$route.meta.headerBackToActions && ($route.name === 'invoiceConnector-edit')"
          class="mx-1"
          @clickAction="$router.push({ name: 'invoiceConnector-show', params: { id: $route.params.id } })"
          :name="$t('global.buttons.cancel')"
          :bgStyle="'light'"
      />
      <MainButton
          v-else-if="this.$route.meta.headerBackToActions && ($route.name === 'user-edit')"
          class="mx-1"
          @clickAction="$router.push({ name: 'user-show', params: { id: $route.params.id } })"
          :name="$t('global.buttons.cancel')"
          :bgStyle="'light'"
      />
      <MainButton
          v-else-if="this.$route.meta.headerBackToActions && ($route.name === 'task-create')"
          class="mx-1"
          @clickAction="$router.push({ name: 'boards' })"
          :name="$t('global.buttons.cancel')"
          :bgStyle="'light'"
      />
      <MainButton
          v-if="this.$route.meta.headerAddNewAction"
          :name="$t('global.buttons.add_new')"
          @clickAction="handleAddNew"
      />
      <MainButton
          v-if="this.$route.meta.headerAddNewActionModal"
          :name="$t('global.buttons.add_new')"
          @clickAction="handleAddNewModal"
      />
      <SaveEditActionButtons
          v-if="this.$route.meta.editableAction"
          @handleEdit="handleEdit"
      />
      <MainButton
          v-if="this.$route.meta.saveAction"
          @clickAction="handleSaveForm"
          :name="$t('global.buttons.save')"
      />
      <CreateBoardDropDown v-if="this.$route.meta.headerIsCreateBoardDropDown" />
      <CreateTaskActions v-if="this.$route.meta.headerIsCreateTaskActions"/>
<!--        <div  v-if="this.$route.meta.headerGoBackIcon" class="px-7 py-2 cursor-pointer bg-white text-14 testClass hover:bg-gray-100 font-medium  text-black  border-primary border ml-3 rounded-md">-->
<!--          <a-->
<!--            @click="-->
<!--              $route.meta.backToPath-->
<!--                ? $router.push($route.meta.backToPath)-->
<!--                : $router.go(-1)-->
<!--            "-->
<!--          >-->
<!--            {{$t('global.buttons.back')}}-->
<!--          </a>-->
<!--        </div>-->
      <SendInvoiceDropDown v-if="this.$route.meta.headerIsSendInvoice"/>
      <CalendarEventsDropDown v-if="this.$route.meta.headerSyncCalendarEventsAction" />
    </div>
  </div>
  </div>
</template>

<script>
import SaveEditActionButtons from '@/components/Layout/Header/SaveEditActionButtons/SaveEditActionButtons';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import { EventBus } from '@/eventBus/event-bus';
import CreateBoardDropDown from '@/components/DropDowns/CreateBoardDropDown/CreateBoardDropDown';
import CreateTaskActions from '@/components/DropDowns/CreateTaskActions/CreateTaskActions';
import SendInvoiceDropDown from '@/components/DropDowns/SendInvoiceDropDown/SendInvoiceDropDown';
import CalendarEventsDropDown from '@/components/Calendar/CalendarEventsDropDown/CalendarEventsDropDown';

export default {
  name: 'Header',
  components: {
    CalendarEventsDropDown,
    SendInvoiceDropDown,
    CreateTaskActions,
    CreateBoardDropDown,
    Breadcrumbs,
    SaveEditActionButtons,
    MainButton
  },
  data() {
    return {
      isEdit: false
    }
  },
  methods: {
    handleAddNew() {
      this.$router.push({ path: `${this.$route.name}/create`, params: { createAble: true } })
    },
    handleSaveForm() {
      EventBus.$emit('handleSaveForm');
    },
    handleAddNewModal() {
      EventBus.$emit('handleAddNewWithModal');
    },
    handleEdit(isEdit) {
      this.isEdit = isEdit;
      EventBus.$emit('handleEdit', isEdit);
    }
  }
}
</script>

<style scoped>

</style>

