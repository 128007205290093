<template>
  <div class="fixed z-50 inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
              {{title}}
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                {{description}}
              </p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <MainButton
              @clickAction="handleConfirm"
              name="Confirm"
          />
          <div class="mr-1">
            <MainButton
                @clickAction="handleCloseModal"
                name="Cancel"
                bgStyle="light"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import { mapGetters } from 'vuex';

export default {
  name: 'DeleteItemModal',
  components: {
    MainButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'invoices'
    ])
  },
  methods: {
    handleCloseModal() {
      this.$emit('handleCloseModal');
    },
    handleConfirm() {
      this.$emit('handleConfirm');
    }
  }
}
</script>

<style scoped>

</style>
