<template>
  <div v-click-outside="closeDropdown">
    <div>
      <MainButton
        :name="$t('task.addTask')"
        @clickAction="isOpen = !isOpen"
      />
    </div>
    <div v-if="isOpen && getAllListings.length" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
      <div class="py-1" role="none">
        <router-link
          v-for="listing in getAllListings"
          :key="listing.id"
          :to="{ name: 'task-create', query: { listingId: listing.id }}"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 truncate w-52"
        >
          {{ listing.name }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { mapGetters } from 'vuex';

export default {
  name: 'CreateNewTaskDropDown',
  components: {
    MainButton: () => import(/* webpackChunkName: "BoardMainButton" */'@/components/Main/Buttons/MainButton/MainButton')
  },
  directives: {
    ClickOutside
  },
  computed: {
    ...mapGetters({ getAllListings:'getAllListings' })
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    handleToggleDropDown() {
      this.isOpen = !this.isOpen;
    }
  }
}
</script>

<style scoped>

</style>
